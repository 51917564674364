import * as MUI from '@material-ui/lab';

import Button from '@material-ui/core/Button';
import React from 'react';

const Alert = (props) => {
  const renderPrompt = () => {
    const answer = window.confirm('Delete item?');
    if (answer) props.onClick();
  };

  return (
    <MUI.Alert
      severity='error'
      action={
        <Button color='inherit' size='small' onClick={renderPrompt}>
          DELETE
        </Button>
      }
    >
      <strong>Danger Zone!</strong> Here you can delete this item.
    </MUI.Alert>
  );
};

export default Alert;
