import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import isEqual from 'lodash.isequal';

const Image = React.memo(({ alt, src, ...props }) => {
  const [error, setError] = React.useState(false);
  const [attempt, setAttempt] = React.useState(0);

  React.useEffect(() => {
    if (error && attempt < 15) {
      setTimeout(() => {
        setAttempt((a) => ++a);
        setError(false);
      }, 3000);
    }
  }, [error, attempt]);

  if (attempt >= 15) {
    return (
      <div>
        <p>Something went wrong.</p>
        <p>
          If it is a newly uploaded image in a large size, it can take an extra time to process it.
        </p>
        <p>Return to this page after some time.</p>
      </div>
    );
  }

  if (error) {
    return <CircularProgress />;
  }

  return (
    <img
      src={error ? '' : src}
      alt={error ? 'Loading' : alt}
      onError={() => setError(true)}
      {...props}
    />
  );
}, isEqual);

export default Image;
