import React, { useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { AuthContext } from '../store/authContext';

export const PrivateRoute = (props) => {
  const { component: Component, ...restProps } = props;

  const history = useHistory();
  const auth = useContext(AuthContext);

  if (!auth.state.token) {
    history.push('/login');
  }

  if (!Component) return null;

  return <Route {...restProps} render={(routeProps) => <Component {...routeProps} />} />;
};
