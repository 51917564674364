import { useAPI } from './useAPI';
import { useQuery } from 'react-query';

export const useSpecimen = (id) => {
  const API = useAPI();
  const params = useQuery(['specimen', id], () => API.get('/specimen/' + id), {
    refetchOnWindowFocus: false,
  });
  return { ...params, data: params.data ? params.data.data : undefined };
};
