import { queryCache, useMutation } from "react-query";

import { useAPI } from "./useAPI";

export const useThemeMutation = () => {
  const API = useAPI();
  const [create] = useMutation(() => API.post("/theme", { name: "New Theme" }));
  const [update] = useMutation(
    (params) => API.patch("/theme/" + params.id, params.data),
    {
      onSuccess: (data) =>
        queryCache.setQueryData(["theme", data.data.id.toString()], data),
    }
  );

  const [remove] = useMutation((params) => API.delete("/theme/" + params.id));

  return {
    create: async () => {
      return await create();
    },

    update: async (params) => {
      return await update(params);
    },

    remove: async (params) => {
      return await remove(params);
    },
  };
};
