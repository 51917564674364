import { useContext, useRef } from 'react';

import { AuthContext } from '../store/authContext';
import axios from 'axios';

const useAPI = () => {
  const auth = useContext(AuthContext);
  axios.defaults.headers.common.Authorization = 'Bearer ' + auth.state.token;

  const axiosRef = useRef(
    axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  );

  return axiosRef.current;
};

export { useAPI };
