import { queryCache, useMutation } from 'react-query';

import { useAPI } from './useAPI';

export const useSpecimenMutation = () => {
  const API = useAPI();
  const [create] = useMutation((params) =>
    API.post('/specimen', { name: 'New Specimen', ...params.data })
  );
  const [update] = useMutation((params) => API.patch('/specimen/' + params.id, params.data), {
    onSuccess: (data) => queryCache.setQueryData(['specimen', data.data.id.toString()], data),
  });

  const [remove] = useMutation((params) => API.delete('/specimen/' + params.id));

  return {
    create: async (params) => {
      return await create(params);
    },

    update: async (params) => {
      return await update(params);
    },

    remove: async (params) => {
      return await remove(params);
    },
  };
};
