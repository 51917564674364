import React, { useReducer } from 'react';
import { USER_AUTH, USER_UNAUTH } from './actions';

const authReducer = (state, action) => {
  switch (action.type) {
    case USER_AUTH:
      return { token: action.payload };
    case USER_UNAUTH:
      return { token: null };
    default:
      return state;
  }
};

const AuthContext = React.createContext({});

const AuthContextProvider = (props) => {
  const accessToken = sessionStorage.getItem('token');
  const [state, dispatch] = useReducer(authReducer, { token: accessToken ? accessToken : null });
  return <AuthContext.Provider value={{ state, dispatch }}>{props.children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
