import React, { useContext } from "react";

import AppBar from "@material-ui/core/AppBar";
import { AuthContext } from "../../store/authContext";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { PrivateRoute } from "../../utils/PrivateRoute";
import Specimen from "../Specimen";
import { Stats } from "../Stats";
import { Switch } from "react-router-dom";
import Theme from "../Theme";
import Themes from "../Themes";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { USER_UNAUTH } from "../../store/actions";
import { makeStyles } from "@material-ui/core/styles";
import { useAPI } from "../../hooks/useAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },

  nav: {
    marginRight: "4rem",
    "& a": {
      color: "white",
      margin: "0 0.5rem",
    },
  },

  paper: {
    height: "100%",
    maxWidth: 1400,

    margin: theme.spacing(5, "auto"),
    padding: theme.spacing(1, 3, 5, 3),
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const API = useAPI();

  const logout = async () => {
    sessionStorage.removeItem("token");
    auth.dispatch({ type: USER_UNAUTH });
    await API.get("/logout");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            The Botanist Dashboard
          </Typography>
          <div className={classes.nav}>
            <Link to={props.match.path}>Themes</Link>
            <Link to={props.match.path + "/stats"}>Stats</Link>
          </div>
          <Button variant="contained" color="secondary" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Paper className={classes.paper} elevation={0}>
        <Switch>
          <PrivateRoute exact path={props.match.path} component={Themes} />
          <PrivateRoute
            exact
            path={props.match.path + "/stats"}
            component={Stats}
          />
          <PrivateRoute
            exact
            path={props.match.path + "/theme/specimen"}
            component={Specimen}
          />
          <PrivateRoute
            exact
            path={props.match.path + "/theme/specimen/:specimen_id"}
            component={Specimen}
          />
          <PrivateRoute
            exact
            path={props.match.path + "/theme/:theme_id"}
            component={Theme}
          />
          <PrivateRoute
            exact
            path={props.match.path + "/theme/:theme_id/specimen"}
            component={Specimen}
          />
          <PrivateRoute
            exact
            path={props.match.path + "/theme/:theme_id/specimen/:specimen_id"}
            component={Specimen}
          />
        </Switch>
      </Paper>
    </div>
  );
};

export default Dashboard;
