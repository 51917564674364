import React, { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Alert from "../../components/Alert";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dropzone from "../../components/Dropzone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Header from "../../components/Header";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useAPI } from "../../hooks/useAPI";
import { useSpecimenMutation } from "../../hooks/useSpecimenMutation";
import { useTheme } from "../../hooks/useTheme";
import { useThemeMutation } from "../../hooks/useThemeMutation";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "100%",
    maxWidth: 1400,

    margin: theme.spacing(5, "auto"),
    padding: theme.spacing(1, 3, 5, 3),
  },
  buttons: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  formGroup: {
    "& > *": {
      marginBottom: theme.spacing(4),
    },
  },
  theme: {
    marginBottom: theme.spacing(4),
  },
  tableHead: {
    backgroundColor: theme.palette.action.hover,
    fontSize: 14,
  },
}));

const Theme = (props) => {
  const API = useAPI();
  const params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme(params.theme_id);
  const themeMutation = useThemeMutation();
  const specimenMutation = useSpecimenMutation();
  const [updatedFields, setUpdatedFields] = useState({});

  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);

  const openThemes = () => {
    history.push("/dashboard");
  };

  const onChange = (e) => {
    e.persist();
    const booleanFields = ["published"];
    const isBooleanField = booleanFields.includes(e.target.name);

    setUpdatedFields((s) => ({
      ...s,
      [e.target.name]: isBooleanField ? e.target.checked : e.target.value,
    }));
  };

  const onFileDrop = useCallback(
    async (acceptedFiles) => {
      const [file] = acceptedFiles;

      const uploadThemeImage = async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        const uploaded = await API.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const original = uploaded.data.transforms.find(
          (image) => image.id === "original"
        );
        await themeMutation.update({
          id: params.theme_id,
          data: { imageKey: original.key },
        });
      };

      try {
        setUploading(true);
        await uploadThemeImage(file);
        setUploading(false);
      } catch (err) {
        console.log("Upload failed");
        return;
      }
    },
    [API, setUploading, params.theme_id, themeMutation]
  );

  const updateTheme = async () => {
    if (Object.keys(updatedFields).length) {
      await themeMutation.update({ id: params.theme_id, data: updatedFields });
    }
  };

  const onSubmitTheme = async (e) => {
    e.preventDefault();
    setSaving(true);
    await updateTheme();

    setSaving(false);
    openThemes();
  };

  const moveSpecimen = async (specimen, action) => {
    const prevOrder = specimen.order;
    let newOrder;

    switch (action) {
      case "UP":
        newOrder = specimen.order - 1;
        break;
      case "DOWN":
        newOrder = specimen.order + 1;
        break;
      default:
        return;
    }

    await specimenMutation.update({
      id: specimen.id,
      data: { order: newOrder },
    });
    await specimenMutation.update({
      id: theme.data.specimens[newOrder].id,
      data: { order: prevOrder },
    });
    theme.refetch();
  };

  const renderTable = () => {
    return (
      <Table aria-label="specimens table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Thumbnail</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="right">Published</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.specimens.map((specimen, index) => (
            <TableRow key={specimen.uuid}>
              <TableCell scope="row">
                <img
                  alt="Specimen thumbnail"
                  src={specimen.thumbnailURIs[0]}
                  width="50"
                  height="50"
                />
              </TableCell>
              <TableCell scope="row">{specimen.name}</TableCell>
              <TableCell scope="row">
                <Chip
                  size="small"
                  label={specimen.type.toUpperCase()}
                  color={specimen.type === "free" ? "primary" : "secondary"}
                />
              </TableCell>
              <TableCell align="right">
                <Chip
                  size="small"
                  label={specimen.published ? "YES" : "NO"}
                  color={specimen.published ? "secondary" : "primary"}
                />
              </TableCell>
              <TableCell align="right" className={classes.buttons}>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  {index ? (
                    <Button onClick={() => moveSpecimen(specimen, "UP")}>
                      <ArrowDropUpIcon />
                    </Button>
                  ) : null}
                  {fields.specimens.length - 1 !== index ? (
                    <Button onClick={() => moveSpecimen(specimen, "DOWN")}>
                      <ArrowDropDownIcon />
                    </Button>
                  ) : null}
                </ButtonGroup>
              </TableCell>
              <TableCell align="right" className={classes.buttons}>
                <Button
                  variant="contained"
                  disabled={uploading || saving}
                  onClick={() => {
                    updateTheme();
                    history.push(
                      props.match.url + "/specimen/" + specimen.id,
                      specimen
                    );
                  }}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const createSpecimen = async () => {
    const { data } = await specimenMutation.create({
      data: {
        themeId: params.theme_id,
        order: theme.data.specimens.length,
      },
    });
    updateTheme();
    history.push(props.match.url + "/specimen/" + data.id);
  };

  if (theme.isLoading) return <CircularProgress />;
  const fields = Object.assign({}, theme.data, updatedFields);
  return (
    <>
      <Header title={theme.data.name}>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            onClick={openThemes}
            disabled={uploading || saving}
          >
            Back
          </Button>
          <Button
            form="form"
            type="submit"
            color="primary"
            variant="contained"
            disabled={uploading || saving}
          >
            {saving || uploading ? <CircularProgress size={20} /> : "Save"}
          </Button>
        </div>
      </Header>
      <Grid container spacing={3} className={classes.theme}>
        <Grid item xs={6} container direction="column">
          <form id="form" autoComplete="off" onSubmit={onSubmitTheme}>
            <FormGroup className={classes.formGroup}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                name="name"
                value={fields.name}
                onChange={onChange}
                required
              />
              <TextField
                label="Product Id"
                variant="outlined"
                name="productId"
                fullWidth
                value={fields.productId}
                onChange={onChange}
              />
              <div>
                <FormControlLabel
                  name="published"
                  onChange={onChange}
                  control={<Switch checked={fields.published} />}
                  label={fields.published ? <b>Published</b> : "Unpublished"}
                />
              </div>
              <TextField
                name="text"
                className={classes.editor}
                label="Description"
                onChange={onChange}
                multiline
                variant="outlined"
                rows={12}
                value={fields.text}
              />
            </FormGroup>
          </form>
        </Grid>
        <Grid item xs={6} container alignItems="center" justify="center">
          <Dropzone
            onDrop={onFileDrop}
            uploading={uploading}
            thumbnail={fields.thumbnailURI}
          />
        </Grid>
      </Grid>

      <Header title="Specimens">
        <Button
          onClick={createSpecimen}
          variant="contained"
          color="primary"
          disabled={uploading || saving}
        >
          Add
        </Button>
      </Header>

      {renderTable()}

      <Box mt={6}>
        <Alert
          onClick={async () => {
            await themeMutation.remove({ id: params.theme_id });
            openThemes();
          }}
        />
      </Box>
    </>
  );
};

export default Theme;
