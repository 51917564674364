import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from '../../components/Header';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useThemeMutation } from '../../hooks/useThemeMutation';
import { useThemes } from '../../hooks/useThemes';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.action.hover,
    fontSize: 14,
  },
  info: {
    marginBottom: theme.spacing(4),

    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Themes = (props) => {
  const themes = useThemes();
  const classes = useStyles();
  const history = useHistory();
  const themeMutation = useThemeMutation();

  const renderTable = () => {
    return (
      <Table aria-label='theme table'>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Product id</TableCell>
            <TableCell align='right'>Specimens</TableCell>
            <TableCell align='right'>Published</TableCell>
            <TableCell align='right'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {themes.data.map((theme) => (
            <TableRow key={theme.id}>
              <TableCell component='th' scope='row'>
                {theme.name}
              </TableCell>
              <TableCell align='right'>{theme.productId}</TableCell>
              <TableCell align='right'>{theme.specimens.length}</TableCell>
              <TableCell align='right'>
                <Chip
                  size='small'
                  label={theme.published ? 'YES' : 'NO'}
                  color={theme.published ? 'secondary' : 'primary'}
                />
              </TableCell>
              <TableCell align='right'>
                <Button
                  variant='contained'
                  onClick={() => {
                    history.push(props.match.path + '/theme/' + theme.id);
                  }}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const createTheme = async () => {
    const { data } = await themeMutation.create();
    history.push(props.match.path + '/theme/' + data.id);
  };

  return (
    <>
      <Header title='Themes'>
        <Button variant='contained' color='primary' onClick={createTheme}>
          Create
        </Button>
      </Header>

      {themes.isLoading ? <CircularProgress /> : renderTable()}
    </>
  );
};

export default Themes;
