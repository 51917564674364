import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { useStats } from "../../hooks/useStats";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.action.hover,
    fontSize: 14,
  },
  info: {
    marginBottom: theme.spacing(4),

    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const sortPieces = (a, b) => b.numberOfPieces - a.numberOfPieces;
const sortTimestamp = (a, b) =>
  new Date(b.lastPieceCreatedAt) - new Date(a.lastPieceCreatedAt);

export const Stats = () => {
  const stats = useStats();
  const classes = useStyles();

  const [activeSort, setActiveSort] = React.useState("pieces");

  if (stats.isError) return <div>Error</div>;
  if (stats.isLoading) return <div>Loading</div>;

  const renderTable = (data) => {
    return (
      <Table aria-label="theme table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Pieces</TableCell>
            <TableCell>Last Piece Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .sort(activeSort === "pieces" ? sortPieces : sortTimestamp)
            .map((user) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  {user.id}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.createdAt}</TableCell>
                <TableCell>{user.numberOfPieces}</TableCell>
                <TableCell>{user.lastPieceCreatedAt}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <div>
      <div>Users: {stats.data.length}</div>
      <div>
        Pieces:{" "}
        {stats.data.reduce(
          (acc, current) => (acc += current.numberOfPieces),
          0
        )}
      </div>
      <br />
      <div>
        Sort by:{" "}
        <span className={classes.link} onClick={() => setActiveSort("pieces")}>
          Pieces
        </span>{" "}
        | 
        <span
          className={classes.link}
          onClick={() => setActiveSort("timestamp")}
        >
          Last Piece Created
        </span>
      </div>
      <br />
      {renderTable(stats.data)}
    </div>
  );
};
