import "./index.css";

import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { AuthContextProvider } from "./store/authContext";
import Dashboard from "./views/Dashboard";
import Login from "./views/Login";
import { PrivateRoute } from "./utils/PrivateRoute";
import React from "react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "react-query-devtools";

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </AuthContextProvider>
    <ReactQueryDevtools initialIsOpen />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
