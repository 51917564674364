import CircularProgress from '@material-ui/core/CircularProgress';
import Image from '../../components/Image';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    textAlign: 'center',
  },
  image: {
    height: '485px',
    width: '100%',
    objectFit: 'contain',
  },
}));

const Dropzone = ({ thumbnail, onDrop, uploading }) => {
  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop,
  });

  return (
    <Paper elevation={0} variant='outlined' className={classes.paper} {...getRootProps()}>
      <input {...getInputProps()} />
      {uploading ? (
        <CircularProgress />
      ) : thumbnail ? (
        <Image width='300' height='300' src={thumbnail} className={classes.image} />
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </Paper>
  );
};

export default Dropzone;
