import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Alert from '../../components/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Header from '../../components/Header';
import Image from '../../components/Image';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import arrayMove from 'array-move';
import { makeStyles } from '@material-ui/core/styles';
import { useAPI } from '../../hooks/useAPI';
import { useDropzone } from 'react-dropzone';
import { useSpecimen } from '../../hooks/useSpecimen';
import { useSpecimenMutation } from '../../hooks/useSpecimenMutation';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },

  tableHead: {
    backgroundColor: theme.palette.action.hover,
    fontSize: 14,
  },

  formGroup: {
    '& > *': {
      marginBottom: theme.spacing(4),
    },
  },
  card: {
    maxWidth: 345,
  },
  media: {
    width: '100%',
    height: 200,
    objectFit: 'contain',
  },
}));

const Specimen = (props) => {
  const API = useAPI();
  const params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const specimenMutation = useSpecimenMutation();
  const specimen = useSpecimen(params.specimen_id);
  const [uploading, setUploading] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({});

  const openTheme = () => {
    history.goBack();
  };

  const onChange = (e) => {
    e.persist();
    const isTypeField = e.target.name === 'type';
    if (isTypeField) {
      const paid = e.target.checked;
      setUpdatedFields((s) => ({
        ...s,
        [e.target.name]: paid ? 'paid' : 'free',
      }));
      return;
    }

    const booleanFields = ['published'];
    const isBooleanField = booleanFields.includes(e.target.name);

    setUpdatedFields((s) => ({
      ...s,
      [e.target.name]: isBooleanField ? e.target.checked : e.target.value,
    }));
  };

  const saveSpecimen = async (e) => {
    e.preventDefault();
    setSaving(true);
    await specimenMutation.update({ id: params.specimen_id, data: updatedFields });
    setSaving(false);
    openTheme();
  };

  const onRemoveSample = async (index) => {
    const sampleKeys = specimen.data.sampleKeys.concat();
    sampleKeys.splice(index, 1);

    await specimenMutation.update({
      id: params.specimen_id,
      data: { sampleKeys },
    });
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const uploadThemeImage = async (files) => {
        const keys = [];
        for (const file of files) {
          const formData = new FormData();
          formData.append('file', file);
          const uploaded = await API.post('/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          const original = uploaded.data.transforms.find((image) => image.id === 'original');
          keys.push(original.key);
        }

        await specimenMutation.update({
          id: params.specimen_id,
          data: { sampleKeys: specimen.data.sampleKeys.concat(keys) },
        });
      };

      try {
        setUploading(true);
        await uploadThemeImage(acceptedFiles);
        setUploading(false);
      } catch (err) {
        console.log('Upload failed');
        setUploading(false);
        return;
      }
    },
    [API, setUploading, specimen, params.specimen_id, specimenMutation]
  );

  const moveSample = async (currentIndex, action) => {
    const sampleKeys = specimen.data.sampleKeys;
    let reorderedSampleKeys;

    switch (action) {
      case 'UP':
        reorderedSampleKeys = arrayMove(sampleKeys, currentIndex, currentIndex - 1);
        break;
      case 'DOWN':
        reorderedSampleKeys = arrayMove(sampleKeys, currentIndex, currentIndex + 1);
        break;
      default:
        return;
    }

    await specimenMutation.update({
      id: specimen.data.id,
      data: { sampleKeys: reorderedSampleKeys },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: 'image/*',
  });

  const renderCard = (src, index) => {
    return (
      <Card className={classes.card}>
        <CardMedia component={() => <Image className={classes.media} src={src} />} title='Sample' />
        <CardActions>
          <ButtonGroup color='primary' aria-label='outlined primary button group'>
            {index ? (
              <Button onClick={() => moveSample(index, 'UP')}>
                <KeyboardArrowLeftIcon />
              </Button>
            ) : null}
            {fields.sampleKeys.length - 1 !== index ? (
              <Button onClick={() => moveSample(index, 'DOWN')}>
                <KeyboardArrowRightIcon />
              </Button>
            ) : null}
          </ButtonGroup>
          <Button
            size='small'
            color='secondary'
            onClick={() => onRemoveSample(index)}
            disabled={uploading}
          >
            Remove
          </Button>
        </CardActions>
      </Card>
    );
  };

  if (specimen.isLoading) return <CircularProgress />;
  const fields = Object.assign({}, specimen.data, updatedFields);
  return (
    <>
      <Header title={specimen.data.name}>
        <div className={classes.buttons}>
          <Button variant='contained' onClick={openTheme} disabled={uploading || saving}>
            Back
          </Button>
          <Button
            form='form'
            type='submit'
            color='primary'
            variant='contained'
            disabled={uploading || saving}
          >
            {saving ? <CircularProgress size={20} /> : 'Save'}
          </Button>
        </div>
      </Header>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <form id='form' onSubmit={saveSpecimen}>
            <FormGroup className={classes.formGroup}>
              <TextField
                label='Name'
                variant='outlined'
                fullWidth
                value={fields.name}
                name='name'
                onChange={onChange}
                required
              />
              <TextField
                required
                fullWidth
                label='Height, cm'
                variant='outlined'
                value={fields.height || ''}
                helperText='Example: 5.5'
                name='height'
                onChange={onChange}
                error={isNaN(fields.height)}
              />
              <div>
                <FormControlLabel
                  control={
                    <Switch checked={fields.type === 'paid'} name='type' onChange={onChange} />
                  }
                  label='Paid'
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch checked={fields.published} name='published' onChange={onChange} />
                  }
                  label='Published'
                />
              </div>
            </FormGroup>
          </form>
        </Grid>
      </Grid>

      <Header title='Samples'>
        <Button variant='outlined' {...getRootProps()} disabled={uploading}>
          {uploading ? <CircularProgress size={20} /> : 'Select/Drag samples here'}
          <input style={{ display: 'none' }} {...getInputProps()} />
        </Button>
      </Header>

      <Grid container spacing={3}>
        {fields.thumbnailURIs.map((thumbnail, index) => {
          return (
            <Grid item xs={6} md={2} key={thumbnail}>
              {renderCard(thumbnail, index)}
            </Grid>
          );
        })}
      </Grid>

      <Box mt={6}>
        <Alert
          onClick={async () => {
            await specimenMutation.remove({ id: params.specimen_id });
            openTheme();
          }}
        />
      </Box>
    </>
  );
};

export default Specimen;
